<template>
  <!-- eslint-disable-next-line -->
  <div>Show me around</div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({});
</script>
